import React from 'react';
import Layout from '../components/layout2';
import SEO from '../components/seo';
import About from '../containers/about';
import Cesium3 from "../components/cesium/cesium3dis"


type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = (props) => {
  return (
    <Layout>
      <SEO
        title="About Us"
        description="StoryHub is a beautiful Gatsby Blog theme designed to showcase your work in style. Perfect for designers, artists, photographers and developers to use for their portfolio website."
      />
      <Cesium3 />
      <About />
    </Layout>
  );
};


export default AboutPage;



